// LICENSE_CODE MIT
import node_url from 'url';
import xdate from './date.js';

let E = {};
export default E;

// XXX: add support for parse_float and fix in src/back/app/index.js
let is_parse_float_feature;
let parse_float = _s=>{
  // XXX: add here fully testing string is a float
  return parseFloat(_s);
};

E.parse_stream = data=>{
  let arr = data.split(/^[\r\n]+$/ugm);
  let ret = [];
  for (let _data of arr)
  {
    let _arr = _data.split(/[\r\n]+/ugm);
    let o;
    for (let __data of _arr)
    {
      if (!__data || /^[\s\r\n]*$/u.test(__data))
        continue;
      let i = __data.indexOf(':');
      // some_key: some_value_here
      let v = __data.substring(i+2);
      let key = __data.substring(0, i);
      let j = E.json_parse(v);
      if (!o)
        o = {};
      if (j && isNaN(j))
      {
        o[key] = j;
        continue;
      }
      o[key] = v;
    }
    if (o)
      ret.push(o);
  }
  return ret;
};

E.parse = url=>node_url.parse(url);
E.qs = (qs_o, is_no_percent)=>{
  let ret = '';
  for (let k in qs_o)
  {
    let v = qs_o[k];
    if (v===undefined)
      continue;
    if (v!==null && typeof v=='object')
      v = JSON.stringify(v);
    ret += '&'+k+'='+(v===null ? ''
      : is_no_percent ? encodeURIComponent(v).replaceAll('%2B',
        '+').replaceAll('%40', '@')
        : encodeURIComponent(v));
  }
  return ret.substr(1);
};

let revive = (k, v)=>{
  if (typeof v != 'string')
    return v;
  let _v = v.toLowerCase();
  if (k=='txt' || k=='text')
    return v;
  if (_v=='true')
    return true;
  if (_v=='false')
    return false;
  return xdate.date_revive(k, v);
};

E.json_parse = json=>{
  try { return JSON.parse(json, revive); }
  catch (e){}; // eslint-disable-line
};

E.qs_parse = (qs_s, has_question, is_crash_any_err, is_no_n, is_to_null)=>{
  let ret = {};
  let i = qs_s.indexOf('?');
  if (has_question && i>-1)
  {
    qs_s = qs_s.substr(i+1);
    i = qs_s.indexOf('?');
  }
  if (i>-1)
    return {};
  qs_s = qs_s.split(/[&]/u);
  for (let k of qs_s)
  {
    let s = k.split('=');
    if (!s.length)
      continue;
    if (s.length==1)
    {
      if (!s[0])
        continue;
      ret[s[0]] = true;
      continue;
    }
    if (s[1]=='')
    {
      ret[s[0]] = is_to_null ? null : undefined;
      continue;
    }
    if (s[1].toLowerCase()=='true')
    {
      ret[s[0]] = true;
      continue;
    }
    if (s[1].toLowerCase()=='false')
    {
      ret[s[0]] = false;
      continue;
    }
    let _s;
    try {
      _s = decodeURIComponent(s[1]);
    } catch(e) {
      if (is_crash_any_err)
        throw e;
      if (e instanceof URIError)
      {
        _s = s[1];
        ret[s[0]] = _s;
        continue;
      }
      else
        throw e;
    }
    let d = xdate.date_revive(null, _s);
    if (d instanceof Date)
    {
      ret[s[0]] = d;
      continue;
    }
    let j = E.json_parse(_s);
    if (j && (isNaN(j) || j instanceof Array))
    {
      ret[s[0]] = j;
      continue;
    }
    let res = isNaN(_s)||is_no_n ? _s : parseInt(_s, 10);
    if (res.toString()!=_s)
    {
      if (is_parse_float_feature)
        res = parse_float(_s);
      else
        res = _s;
    }
    ret[s[0]] = res;
  }
  return ret;
};

E.qs_o_parse = qs_o=>E.qs_parse(E.qs(qs_o));
E.url2o = (url, is_no_n, is_to_null)=>{
  let i_qs = url.indexOf('?');
  let i_hs = url.indexOf('#');
  let uri;
  if (i_qs<0 && i_hs<0)
  {
    uri = url.substring(0, url.length);
    return {uri, qs_o: {}, hs_o: {}};
  }
  else if (i_qs<0)
    uri = url.substring(0, i_hs);
  else
    uri = url.substring(0, i_qs);
  let qs_o = {}, hs_o = {};
  if (i_qs>-1)
  {
    let qs_s = url.substring(i_qs+1, i_hs>-1 ? i_hs : undefined);
    qs_o = E.qs_parse(qs_s, undefined, undefined, is_no_n, is_to_null);
  }
  if (i_hs>-1)
  {
    let hs_s = url.substring(i_hs+1);
    hs_o = E.qs_parse(hs_s, undefined, undefined, is_no_n, is_to_null);
  }
  return {uri, qs_o, hs_o};
};

E.url2qs_o = (url, is_no_n, is_to_null)=>E.url2o(url, is_no_n,
  is_to_null).qs_o;
E.url2hs_o = url=>E.url2o(url).hs_o;
E.url2uri = url=>E.url2o(url).uri;

E.url = (uri, qs_o, hs_o, is_no_percent)=>{
  let ret = '';
  let qs = E.qs(qs_o, is_no_percent);
  let hs = E.qs(hs_o, is_no_percent);
  if (qs)
    ret += '?'+qs;
  if (hs)
    ret += '#'+hs;
  return uri+ret;
};

