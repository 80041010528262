// LICENSE_CODE MIT
let E = {};
export default E;

E.path = function(path){
  // XXX: add support for array index
  if (Array.isArray(path))
    return path;
  path = ''+path;
  if (!path)
    return [];
  return path.split('.');
};

E.get = function(o, path, def){
  path = E.path(path);
  for (let i=0; i<path.length; i++)
  {
    if (!o || typeof o!='object'&&typeof o!='function' || !(path[i] in o))
      return def;
    o = o[path[i]];
  }
  return o;
};

E.clone = o=>{
  // XXX colin: unuspported
  return {...o};
};

E.clamp = (n, min, max)=>{
  n = +n;
  min = +min;
  max = +max;
  n = n <= max ? n : max;
  n = n >= min ? n : min;
  return n;
};

E.set = (o, path, value)=>{
  let orig = o;
  path = E.path(path);
  for (let i=0; i<path.length-1; i++)
  {
    let p = path[i];
    o = o[p] || (o[p] = {});
  }
  o[path[path.length-1]] = value;
  return orig;
};

E.set_inc = (o, path, v)=>{
  let prev = E.get(o, path);
  if (prev===undefined)
    return E.set(o, path, v);
  return E.set(o, path, prev+v);
};

E.unset = (o, path)=>{
  path = E.path(path);
  for (let i=0; i<path.length-1; i++)
  {
    let p = path[i];
    if (!o[p])
      return;
    o = o[p];
  }
  delete o[path[path.length-1]];
};

E.semver = {};
E.semver.cmp = (v1, v2)=>{
  // 0 v1==v2
  // 1 v1>v2
  // -1 v1<v2
  return v1.localeCompare(v2, undefined, {numeric: true, sensitivity: 'base'});
};

E.is_empty = o=>!Object.keys(o||{}).length;

E.obj_is_equal = (obj1, obj2)=>JSON.stringify(obj1)==JSON.stringify(obj2);

E.objs_limit = (objs, field='ts.insert', limit=10)=>{
  let entries = Object.entries(objs);
  entries.sort((a, b)=>E.get(b[1], field)-E.get(a[1], field));
  let i = 0;
  for (let [key, v] of entries)
  {
    i++;
    if (i<=limit && objs[key])
      continue;
    delete objs[key];
  }
  return objs;
};

// XXX: use math.fraction instead
E.fraction = num=>{
  let x = num % 1;
  let y = x;
  let i = 0;
  if (y%1==0)
    return {n: num, d: 1};
  while (y % 1 != 0)
  {
    i++;
    y = x*i;
  }
  return {n: num*i, d: i};
};
