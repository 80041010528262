// LICENSE_CODE MIT
let E = {};
export default E;

E.trim = s=>{
  return s.replace(/\s+/ug, ' ').replace(/^\s+|\s+$/ug, '');
};

E.trimh = (s, is_allow_empty_newline)=>{
  let ret = '';
  let lines = s.split('\n');
  for (let l of lines)
  {
    let _l = l.trim();
    if (!_l && !is_allow_empty_newline)
      continue;
    ret += _l+'\n';
  }
  return ret;
};
E.cap_first = s=>{
  if (!s)
    return '';
  s = s.trim();
  if (s == '')
    return '';
  let words = s.split(' ');
  for (let i in words)
  {
    let word = words[i].toLowerCase();
    if (['ai'].includes(word))
      continue;
    words[i] = word.toLowerCase();
  }
  words[0] = words[0][0].toUpperCase() + words[0].substring(1);
  return words.join(' ');
};
E.cap_title = E.cap_first;
E.s2a = s=>E.trim(s).split(' ');
E.j2s = (json, flat)=>{
  if (flat)
    return JSON.stringify(json);
  return JSON.stringify(json, null, 2);
};
E.large_n2lbl = num=>{
  let ret = E.n2lbl(num);
  ret = ret.toString().replace(/\B(?=(\d{3})+(?!\d))/ug, ',');
  return ret;
};
E.n2lbl = num=>+num.toFixed(2)+'';
E.n2s_pad = (num, pad)=>(num+'').padStart(pad, '0');
E.currency2lbl = E.n2lbl;
E.is_valid_mongo_field = field=>/\./u.test(field);
E.email2mongo = email=>email.replace(/\./ug, '@@');
E.mongo2email = id=>id.replace(/@@/ug, '.');
E.ip2mongo = email=>email.replace(/\./ug, '_');
E.mongo2ip = id=>id.replace(/_/ug, '.');
E.mongo2domain = id=>id.replace(/_/ug, '.');
E.dir_forbidden_chars = ['/', '\\', '?', ':', '*', '<', '>', '"', '|', '$'];
E.path2mongo = path=>encodeURIComponent(path).replaceAll('.', '%2E')
  .replaceAll('%40', '@').replaceAll('%2B', '+').replaceAll('%20', ' ')
  .replaceAll('%2F', '/');
E.mongo2path = mongo_field=>decodeURIComponent(
  mongo_field.replaceAll('@', '%40').replaceAll(' ', '%20'));
E.domain2mongo = domain=>domain.replace(/\./ug, '_');
E.str2reg = (_str, flags)=>{
  flags = flags || 'uig';
  _str = _str.replace(/[.*+?^${}()|[\]\\]/ug, '\\$&');
  return new RegExp(_str, flags);
};
E.is_str = obj=>typeof obj === 'string';
E.base642s = base64=>{
  let buf = Buffer.from(base64, 'base64');
  return buf.toString('utf-8');
};

E.s2base64 = s=>{
  let buf = Buffer.from(s, 'utf-8');
  return buf.toString('base64');
};

E.phone2lbl = phone=>{
  let area = phone.substr(1, 3);
  let first = phone.substr(4, 3);
  let second = phone.substr(7, 4);
  return `+${area}-${first}-${second}`;
};

E.trun = (s, size)=>{
  return s.length > size ? s.slice(0, size-1) + '...' : s;
};

E.cmp = (s1, s2)=>s1.localeCompare(s2, undefined, {numeric: true});
