// LICENSE_CODE MIT
import fs from 'fs'; // XXX colin: use fs.js
import process from 'process';
import xpath from 'path';
import os from 'os';
import readline from 'readline';
import url from 'url';
import child_process from 'child_process';
//import {spawnSync, spawn} from 'child_process';
import str from './str.js';
import eserf from './eserf.js';

// XXX: done to support vite remove once add polyfill to vite
let spawnSync = child_process?.spawnSync, spawn = child_process?.spawn;
let E = {};
export default E;

E.is_node = process&&process.title!='browser';
E.is_test = process&&process.env.IS_TEST;
E.is_win = os.platform()=='win32';
E.is_mac = os.platform()=='darwin';
E.is_arm = process.arch=='arm' || process.arch=='arm64';
E.is_linux = !E.is_win && !E.is_mac;
let _is_docker;
E.is_docker = ()=>{
  if (_is_docker===undefined)
    _is_docker = fs.existsSync('/.dockerenv');
  return _is_docker;
};

E.__dirname = (import_meta_url, import_meta_main)=>{
  let is_bun_single_exe = E.is_bun_single_exe(import_meta_main);
  if (is_bun_single_exe)
    return xpath.dirname(process.execPath);
  return xpath.dirname(url.fileURLToPath(import_meta_url));
};

E.is_bun_single_exe = import_meta_main=>{
  return process.versions.bun && import_meta_main;
};

E.cwd = ()=>process.cwd();
E.tmpdir = ()=>os.tmpdir();

E.is_main = (import_meta_url, opt)=>{
  if (!E.is_node)
    return;
  // support bun single exe build entrypoint
  // XXX colin: when single exe in bun main is always true doesnt matter where
  // it's loaded
  if (import_meta_url.includes(E.is_win ? '~BUN' : '$bunfs'))
    return opt?.import_meta_main;
  let is_mjs_parent = opt?.is_mjs_parent;
  let curr_path = url.fileURLToPath(import_meta_url);
  let [bin_path, mod_path] = process.argv;
  if (is_mjs_parent && mod_path.endsWith('.mjs'))
    mod_path = mod_path.slice(0, mod_path.length-4);
  return curr_path == mod_path || curr_path == mod_path+'.js'
    || curr_path == mod_path+'.cjs' || curr_path == mod_path+'.mjs';
};
E.cur_pid = ()=>process.pid;
// XXX colin: add eserf support
E.spawn = (argv, cwd, is_print, shell, _env, no_console, detached,
  max_buffer=1024*1024)=>{
  let env = process.env;
  if (_env)
    env = Object.assign({}, process.env, _env);
  cwd = cwd||process.cwd();
  if (typeof argv == 'string')
    argv = argv.split(' ');
  if (!no_console)
  {
    let cmd_out = 'cd '+cwd+' &&';
    if (_env)
    {
      for (let k in _env)
        cmd_out += ` ${k}="${_env[k]}"`;
    }
    cmd_out += ' '+argv.join(' ');
    console.log(cmd_out);
  }
  let stdio = is_print ? 'inherit' : undefined;
  // XXX colin: add escaping if shell
  return spawnSync(argv.shift(), argv,
    {encoding: 'utf-8', stdio, cwd, shell, env, detached,
      maxBuffer: max_buffer});
};

E.spawn_e = (argv, cwd, is_print, shell, env, no_console)=>{
  if (typeof argv == 'string')
    argv = argv.split(' ');
  let res = E.spawn(argv, cwd, is_print, shell, env, no_console);
  if (res.status || res.errno || res.error)
    throw new Error(`${argv.join(' ')} failed: ${str.j2s(res)}`);
  return res;
};

let memory_limit = 1288490189;
let memory_limit_diff = 214748365;
let memory_limit_alert_level = 0;

E.mem_usage = memory_limit_cb=>{
  let usage = process.memoryUsage().heapUsed;
  let next_alert_limit = memory_limit +
    memory_limit_diff * memory_limit_alert_level;
  if (usage >= next_alert_limit)
  {
    if (memory_limit_cb)
      memory_limit_cb(usage, next_alert_limit, memory_limit_alert_level);
    memory_limit_alert_level++;
    let mb = (usage/(1024*1024)).toFixed(2);
    console.log(`memory check ${mb} mb`);
  }
  return usage;
};
E.print_memory_usage = (opt = {})=>{
  let output = opt.output || console.log;
  let usage = E.mem_usage();
  let mb = (usage/(1024*1024)).toFixed(2);
  let log = `memory usage ${mb} mb`;
  if (opt.tag)
    log = `${opt.tag} ${log}`;
  output(log);
};
const escape2single = s=>{
  s = ''+s;
  if (!s)
    return '""';
  // eslint-disable-next-line no-useless-escape
  if ((/^[a-z0-9_\-.\/:]+$/ui).test(s))
    return s;
  return '"'+s.replace(/([\\"`$])/ug, '\\$1')+'"';
};

E.escape = (...args)=>{
  let _a = args[0];
  if (args.length==1 && !Array.isArray(_a))
    return escape2single(_a);
  let s = '', a = Array.isArray(_a) ? _a : args;
  for (let i=0; i<a.length; i++)
    s += (i ? ' ' : '')+escape2single(a[i]);
  return s;
};

E.espawn = (argv, opt, on_stdout, on_stderr)=>eserf(function* espawn(){
  let cancelled = true;
  opt = opt || {};
  let {cwd, is_print, shell, env, no_console, detached,
    is_ignore, is_no_print_err} = opt;
  env = env||process.env;
  cwd = cwd||process.cwd();
  if (typeof argv == 'string')
    argv = argv.split(' ');
  if (!no_console)
    console.log('cd '+cwd+' &&', argv.join(' '));
  let stdio = is_print ? 'inherit' : is_ignore ? 'ignore' : undefined;
  // XXX colin: add escaping if shell
  let p = spawn(argv.shift(), argv,
    {encoding: 'utf-8', stdio, cwd, shell, env, detached});
  this.finally(()=>{
    if (!p || !cancelled)
      return;
    process.kill(p.pid, 'SIGINT');
  });
  let _stdout = '';
  let _stderr = '';
  p.on('close', code=>{
    if (code!=0 && !is_no_print_err && !no_console)
      console.log(`process exited with code ${code}`);
    this.continue({code: code, stdout: _stdout, stderr: _stderr});
  });
  if (!stdio)
  {
    p.stdout.on('data', data=>{
      if (on_stdout)
        return on_stdout('notice', data);
      _stdout += data;
    });
    p.stderr.on('data', data=>{
      if (is_no_print_err)
        return;
      if (on_stderr)
        return on_stderr('error', data);
      if (!no_console)
        console.error(`stderr: ${data}`);
      _stderr += data;
    });
  }
  let res = yield this.wait();
  cancelled = false;
  return {...p, code: res.code, stdout: res.stdout, stderr: res.stderr};
});

E.espawn_e = (argv, opt)=>eserf(function*(){
  if (typeof argv == 'string')
    argv = argv.split(' ');
  let p = yield E.espawn(argv, opt);
  if (p.exitCode)
    throw new Error(`${argv.join(' ')} failed: ${str.j2s(p)}`);
  return p;
});

E.readline = txt=>eserf(function* _proc_readline(){
  let rl = readline.createInterface({input: process.stdin,
    output: process.stdout});
  rl.question(txt, prompt=>{
    rl.close();
    this.continue(prompt);
  });
  let prompt = yield this.wait();
  return prompt.trim();
});

E.init = (uninit_before_exit, uninit_exit)=>{
  process.on('uncaughtException', (err, origin)=>{
    fs.writeSync(process.stderr.fd, `caught exception ${err}\n`
      +`origin ${origin}`);
  });
  process.on('unhandledPromiseRejection', (err, origin)=>{
    fs.writeSync(process.stderr.fd, `caught exception ${err}\n`
      +`origin ${origin}`);
  });
  process.on('exit', (...args)=>{
    console.log('exit', ...args);
    if (uninit_exit)
      uninit_exit();
  });
  let uninited;
  process.on('SIGINT', (...args)=>eserf(function* proc_sigint(){
    if (uninited)
      console.error('already uninited');
    uninited = true;
    console.log('SIGINT', ...args);
    if (uninit_before_exit)
      yield uninit_before_exit();
    process.exit();
  }));
  process.on('SIGTERM', (...args)=>eserf(function* proc_sigterm(){
    if (uninited)
      console.error('already uninited');
    uninited = true;
    console.log('SIGTERM', ...args);
    if (uninit_before_exit)
      yield uninit_before_exit();
    process.exit();
  }));
};
E.uninit = ()=>{
  if (0)
    console.log('proc.uninit eserf ps', eserf.ps());
};
