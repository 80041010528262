// LICENSE_CODE TLM
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Typography, Layout, theme, Button, Dropdown, message, Input, Table,
  Select, Tree, Modal, Form} from 'antd';
import {MoreOutlined, SearchOutlined, DownOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import {closestCenter as closest_center, DndContext, DragOverlay, PointerSensor,
  useSensor, useSensors} from '@dnd-kit/core';
import {restrictToHorizontalAxis as restrict_to_horizontal_axis}
  from '@dnd-kit/modifiers';
import {arrayMove,
  horizontalListSortingStrategy as horizontal_list_sorting_strategy,
  SortableContext} from '@dnd-kit/sortable';
import _ from 'lodash';
import {multi_sort, Tbin_table_body_cell, Tbin_table_header_cell,
  Tbin_col_choose_modal, Drag_idx_ctx} from './editor.js';
import str from '../../../util/str.js';
import eserf from '../../../util/eserf.js';
import ereq from '../../../util/ereq.js';
import tc from '../../../util/tc.js';
import {use_effect_eserf} from './comp.js';
import Text_view_mode_icon from './assets/text_view_mode_icon.svg?react';
import Frame_view_mode_icon from './assets/frame_view_mode_icon.svg?react';
import metric from './metric.js';
import config_ext from './config_ext.js';

let Header = React.memo(() => {
  let {t} = useTranslation();
  let {token: {colorBgContainer}} = theme.useToken();
  let dropdown_items = useMemo(()=>{
    return [
      {key: 'settings', label: t('Settings'), disabled: true},
    ];
  }, [t]);
  let dropdown_click_handle = useCallback(()=>{}, []);
  return (
    <Layout.Header style={{background: colorBgContainer, display: 'flex',
      justifyContent: 'space-between', alignItems: 'center',
      padding: '0 24px'}}>
      <Typography.Title level={3} style={{margin: 0}}>
        {t('Project')}
      </Typography.Title>
        <Dropdown menu={{items: dropdown_items, onClick: dropdown_click_handle}}
          placement="bottomRight" trigger={['click']}>
          <Button type="text" shape="circle" icon={<MoreOutlined />} />
        </Dropdown>
    </Layout.Header>
  );
});
let tree_data_get = (list, key)=>{
  if (!list.length)
    return null;
  let queue = [...list];
  while (queue.length)
  {
    let node = queue.shift();
    if (node.key == key)
      return node;
    if (key.startsWith(node.key + '/') && node.children)
      queue.push(...node.children);
  }
  return null;
};
let tree_data_update = (list, key, children)=>{
  return list.map(node=>{
    if (node.key == key)
      return {...node, children};
    if (key.startsWith(node.key + '/') && node.children)
    {
      return {...node, children: tree_data_update(node.children, key,
        children)};
    }
    return node;
  });
};
let File_tree = React.memo(({selected_paths, on_selected_paths_change,
  tree_data, on_tree_data_change})=>{
  let {t} = useTranslation();
  let [message_api, message_ctx_holder] = message.useMessage();
  let load_data_handle = useCallback(({key, children})=>{
    return new Promise((resolve, reject)=>eserf(function*
    _load_data_handle(){
      if (children)
        return resolve();
      let res = yield ereq.get(`${config_ext.back.app.url}/pub/fs/ls.json`,
        {qs: {ver: config_ext.ver, path: key}});
      if (res.err)
        return reject(res.err);
      let new_children = res.data.files
        .map(o=>({title: o.file.split('/').at(-1), key: o.file,
          isLeaf: !o.is_dir}));
      on_tree_data_change(origin=>tree_data_update(origin, key, new_children));
      resolve();
    }));
  }, [on_tree_data_change]);
  let check_handle = useCallback((_selected_paths, e)=>eserf(function*
  _check_handle(){
    if (!e.node.isLeaf && !e.node.children)
    {
      let queue = [e.node.key];
      while (queue.length)
      {
        let path = queue.shift();
        let res = yield ereq.get(`${config_ext.back.app.url}/pub/fs/ls.json`,
          {qs: {ver: config_ext.ver, path}});
        if (res.err)
        {
          message_api.error(t('Something went wrong'));
          return metric.error('pub_fs_ls', res.err);
        }
        let children = res.data.files.map(o=>({title: o.file.split('/').at(-1),
          key: o.file, isLeaf: !o.is_dir}));
        on_tree_data_change(origin=>tree_data_update(origin, path, children));
        let dir_paths = children
          .filter(node=>!node.isLeaf)
          .map(node=>node.key);
        queue.push(...dir_paths);
        let paths = children.map(node=>node.key);
        _selected_paths.push(...paths);
      }
    }
    on_selected_paths_change(_selected_paths);
  }), [message_api, on_selected_paths_change, on_tree_data_change, t]);
  return (
    <div style={{overflow: 'hidden'}}>
      {message_ctx_holder}
      <Tree checkable showLine selectable={false} treeData={tree_data}
        switcherIcon={<DownOutlined />} loadData={load_data_handle}
        checkedKeys={selected_paths} onCheck={check_handle} />
    </div>
  );
});
let Tcode_modal = React.memo(({is_open, on_close, path, root_dir})=>{
  let {t} = useTranslation();
  let [form] = Form.useForm();
  let [message_api, message_ctx_holder] = message.useMessage();
  let [is_loading, is_loading_set] = useState(false);
  let submit_handle = useCallback(()=>eserf(function* _submit_handle(){
    let values = yield this.wait_ext2(form.validateFields());
    if (values.err)
      return;
    is_loading_set(true);
    let res = yield ereq.get(`${config_ext.back.app.url}/pub/tcoder/cmd.json`,
      {qs: {ver: config_ext.ver, cmd: 'tcode', in: path,
        dir: values.output_dir}});
    is_loading_set(false);
    if (res.data.err)
    {
      message_api.error(t('Something went wrong'));
      return metric.error('pub_tcoder_cmd', res.err);
    }
    on_close();
  }), [form, message_api, on_close, path, t]);
  let initial_values = useMemo(()=>{
    return {output_dir: root_dir};
  }, [root_dir]);
  return (
    <Modal title={t('Transcode/Convert')} open={is_open} okText={t('Transcode')}
      onOk={submit_handle} onCancel={on_close} destroyOnClose
      confirmLoading={is_loading} preserve={false}>
      {message_ctx_holder}
      <Form form={form} layout="vertical" preserve={false}
        initialValues={initial_values} onFinish={submit_handle}>
        <Form.Item name="output_dir" label={t('Output directory')}
          rules={[{required: true,
            message: t('Please, input the output directory')},
            {max: 255, message: t('Name is too long')}]}>
          <Input disabled={is_loading} />
        </Form.Item>
      </Form>
    </Modal>
  );
});
let default_col_keys = ['reel_name', 'file_transcode', 'tc', 'fps', 'tracks'];
let Bin_panel = React.memo(({probes, root_dir})=>{
  let {token: {borderRadiusLG}} = theme.useToken();
  let {t} = useTranslation();
  let [selected_objs, selected_objs_set] = useState([]);
  let [selected_cols, selected_cols_set] = useState([]);
  let [is_row_ctx_open, is_row_ctx_open_set] = useState(false);
  let [, ctx_record_set] = useState(null);
  let [is_header_ctx_open, is_header_ctx_open_set] = useState(false);
  let [is_col_choose_modal_open,
    is_col_choose_modal_open_set] = useState(false);
  let [ctx_col, ctx_col_set] = useState(null);
  let [sorters, sorters_set] = useState([]);
  let [view_mode, view_mode_set] = useState('text');
  let [query, query_set] = useState('');
  let [col_keys, col_keys_set] = useState(default_col_keys);
  let [drag_idx, drag_idx_set] = useState({active: null, over: null});
  let [is_tcode_modal_open, is_tcode_modal_open_set] = useState(false);
  let sensors = useSensors(useSensor(PointerSensor, {
    activationConstraint: {distance: 1}}));
  let header_cell_handle = useCallback(col=>{
    return {
      id: col.key,
      onMouseDown: e=>{
        if ((e.ctrlKey || e.metaKey) && selected_cols.includes(col.key))
        {
          let _selected_cols = selected_cols.filter(id=>id != col.key);
          selected_cols_set(_selected_cols);
          selected_objs_set([]);
          return;
        }
        if ((e.ctrlKey || e.metaKey) && !selected_cols.includes(col.key))
        {
          selected_cols_set([...selected_cols, col.key]);
          selected_objs_set([]);
          return;
        }
        selected_cols_set([col.key]);
        selected_objs_set([]);
      },
      onContextMenu: ()=>{
        is_header_ctx_open_set(true);
        ctx_col_set(col.key);
      },
    };
  }, [selected_cols]);
  let cell_handle = useCallback(col=>{
    return {id: col.key};
  }, []);
  let cols = useMemo(()=>[
    {key: 'reel_name', dataIndex: 'reel_name', title: t('Reel Name'),
      _sorter: (a, b)=>str.cmp(b.reel_name, a.reel_name)},
    {key: 'file_transcode', dataIndex: 'file_transcode',
      title: t('File Transcode'),
      _sorter: (a, b)=>str.cmp(b.file_transcode, a.file_transcode)},
    {key: 'tc', dataIndex: 'tc',
      title: t('Timecode'), _sorter: (a, b)=>{
        if (!a.stream.tc)
          return -1;
        if (!b.stream.tc)
          return 1;
        let a_frame = tc.tc_o2frame(tc.str2tc_o(a.stream.tc), null, a.fps);
        let b_frame = tc.tc_o2frame(tc.str2tc_o(b.stream.tc), null, b.fps);
        return b_frame - a_frame;
      }},
    {key: 'fps', dataIndex: 'fps', title: t('FPS'),
      _sorter: (a, b)=>b.fps - a.fps},
    {key: 'tracks', dataIndex: 'tracks',
      title: t('Tracks'), _sorter: (a, b)=>str.cmp(b.tracks, a.tracks)},
    {key: 'source_path', dataIndex: 'source_path', title: t('Source Path'),
      _sorter: (a, b)=>str.cmp(b.source_path, a.source_path)},
    {key: 'source_name', dataIndex: 'source_name', title: t('Source Name'),
      _sorter: (a, b)=>str.cmp(b.source_name, a.source_name)},
    {key: 'umid', dataIndex: 'umid',
      title: t('UMID'), _sorter: (a, b)=>str.cmp(b.umid, a.umid)},
  ], [t]);
  let col_opts = useMemo(()=>{
    return cols.map(col=>({label: col.title, value: col.key}));
  }, [cols]);
  let cols_in_use = useMemo(()=>{
    return col_keys
      .map(key=>{
        let _col = cols.find(col=>col.key == key);
        return {..._col, className: selected_cols.includes(key)
          ? 'bin-table-row-selected' : null, onHeaderCell: header_cell_handle,
        onCell: cell_handle};
      });
  }, [cell_handle, col_keys, cols, header_cell_handle, selected_cols]);
  let data_src = useMemo(()=>{
    let _query = query.toLowerCase().trim();
    let _data_src = Object.values(probes)
      .filter(streams=>{
        return streams.some(stream=>{
          return [...Object.values(stream)].some(value=>{
            return String(value).toLowerCase().includes(_query);
          });
        })
      })
      .map(streams=>{
        let stream = streams[0];
        let tracks = streams.map(stream=>stream.tr).join(', ');
        return {stream, file_transcode: stream.file_transcode,
          reel_name: stream.reel_name, tc: stream.tc, tracks,
          fps: tc.editrate2fps(stream.editrate), source_path: stream.file_orig,
          source_name: stream.file_orig.split('/').at(-1), umid: stream.umid};
      });
    let sort_criteria = sorters.map(({key, dir})=>({dir,
      sorter: cols.find(col=>col.key == key)._sorter}));
    return multi_sort(_data_src, sort_criteria);
  }, [cols, probes, sorters, query]);
  let row_dropdown_items = useMemo(()=>{
    return [
      {label: t('Link files'), key: 'link_files', disabled: true},
      {label: t('Import camera metadate'), key: 'import_cam_metadata',
        disabled: true},
      {label: t('Transcode/convert'), key: 'transcode_convert'},
      {label: t('Export ale'), key: 'export_ale', disabled: true},
    ];
  }, [t]);
  let row_dropdown_click_handle = useCallback(({key})=>{
    if (key == 'transcode_convert')
      is_tcode_modal_open_set(true);
  }, []);
  let header_dropdown_items = useMemo(()=>{
    return [
      {label: t('Choose columns...'), key: 'choose_cols'},
      {label: t('Add Custom Column'), key: 'add_custom_col', disabled: true},
      {label: t('Rename Column'), key: 'rename_col', disabled: true},
      {label: t('Hide Column'), key: 'hide_col', disabled: true},
      {type: 'divider'},
      {label: t('Sort on Column, Ascending'), key: 'sort_asc'},
      {label: t('Sort on Column, Descending'), key: 'sort_desc'},
    ];
  }, [t]);
  let col_preset_options = useMemo(()=>{
    return [
      {label: t('Basic'), value: 'basic', col_keys: ['reel_name',
        'file_transcode', 'tc', 'fps', 'tracks']},
    ];
  }, [t]);
  let col_keys_change = useCallback(_col_keys=>{
    col_keys_set(_col_keys);
    sorters_set([]);
  }, []);
  let row_class_name_handle = useCallback(record=>{
    if (record.is_upload)
      return 'markers-table-row';
    if (selected_objs.includes(record.stream.file_orig))
      return 'bin-table-row bin-table-row-selected';
    return 'bin-table-row';
  }, [selected_objs]);
  let row_handle = useCallback(record=>{
    if (record.is_upload)
      return {};
    let name = record.stream.file_orig;
    return {
      onMouseDown: e=>{
        if ((e.ctrlKey || e.metaKey) && selected_objs.includes(name))
        {
          let _selected_objs = selected_objs.filter(id=>id != name);
          selected_objs_set(_selected_objs);
          selected_cols_set([]);
          return;
        }
        if ((e.ctrlKey || e.metaKey) && !selected_objs.includes(name))
        {
          selected_objs_set([...selected_objs, name]);
          selected_cols_set([]);
          return;
        }
        selected_objs_set([name]);
        selected_cols_set([]);
      },
      onContextMenu: ()=>{
        is_row_ctx_open_set(true);
        ctx_record_set(record);
      },
    };
  }, [selected_objs]);
  let header_dropdown_click_handle = useCallback(({key})=>{
    if (key == 'choose_cols')
      is_col_choose_modal_open_set(true);
    if (key == 'sort_asc')
    {
      let _sorters = [...sorters];
      let idx = _sorters.findIndex(sorter=>sorter.key == ctx_col);
      if (idx != -1)
        _sorters.splice(idx, 1);
      _sorters.unshift({key: ctx_col, dir: 'asc'});
      sorters_set(_sorters);
    }
    if (key == 'sort_desc')
    {
      let _sorters = [...sorters];
      let idx = _sorters.findIndex(sorter=>sorter.key == ctx_col);
      if (idx != -1)
        _sorters.splice(idx, 1);
      _sorters.unshift({key: ctx_col, dir: 'desc'});
      sorters_set(_sorters);
    }
  }, [ctx_col, sorters]);
  let row_open_change_handle = useCallback(is_open=>{
    if (!is_open)
      is_row_ctx_open_set(false);
  }, []);
  let header_open_change_handle = useCallback(is_open=>{
    if (!is_open)
      is_header_ctx_open_set(false);
  }, []);
  let col_choose_modal_close_handle = useCallback(()=>{
    is_col_choose_modal_open_set(false);
  }, []);
  let col_choose_modal_submit_handle = useCallback(_col_keys=>{
    is_col_choose_modal_open_set(false);
    col_keys_change(_col_keys);
    selected_cols_set([]);
  }, [col_keys_change]);
  let text_view_mode_click_handle = useCallback(()=>{
    view_mode_set('text');
  }, []);
  let frame_view_mode_click_handle = useCallback(()=>{
    view_mode_set('frame');
    selected_cols_set([]);
  }, []);
  let card_click_handler_get = useCallback(file_orig=>{
    return e=>{
      if ((e.ctrlKey || e.metaKey) && selected_objs.includes(file_orig))
      {
        let _selected_objs = selected_objs.filter(id=>id != file_orig);
        selected_objs_set(_selected_objs);
        selected_cols_set([]);
        return;
      }
      if ((e.ctrlKey || e.metaKey) && !selected_objs.includes(file_orig))
      {
        selected_objs_set([...selected_objs, file_orig]);
        selected_cols_set([]);
        return;
      }
      selected_objs_set([file_orig]);
      selected_cols_set([]);
    };
  }, [selected_objs]);
  let query_change_handle = useCallback(e=>{
    query_set(e.target.value);
  }, []);
  let select_value = useMemo(()=>{
    let found_preset = col_preset_options.find(preset=>{
      return preset.col_keys.length == col_keys.length
        && preset.col_keys.every((key, idx)=>col_keys[idx] == key);
    });
    if (!found_preset)
      return null;
    return found_preset.value;
  }, [col_keys, col_preset_options]);
  let select_change_handle = useCallback((value, option)=>{
    col_keys_change(option.col_keys);
    selected_cols_set([]);
  }, [col_keys_change]);
  let drag_end_handle = ({active, over})=>{
    if (active.id != over?.id)
    {
      let active_idx = col_keys.findIndex(key=>key == active?.id);
      let over_idx = col_keys.findIndex(key=>key == over?.id);
      col_keys_change(arrayMove(col_keys, active_idx, over_idx));
    }
    drag_idx_set({active: null, over: null});
  };
  let drag_over_handle = ({active, over})=>{
    let active_idx = col_keys.findIndex(col=>col.key == active.id);
    let over_idx = col_keys.findIndex(col=>col.key == over?.id);
    drag_idx_set({active: active.id, over: over?.id,
      direction: over_idx > active_idx ? 'right' : 'left'});
  };
  let dropdown_items = useMemo(()=>{
    return [
      {label: t('Link files'), key: 'link_files', disabled: true},
      {label: t('Import camera metadate'), key: 'import_cam_metadata',
        disabled: true},
      {label: t('Transcode/convert'), key: 'transcode_convert', disabled: true},
      {label: t('Export ale'), key: 'export_ale', disabled: true},
    ];
  }, [t]);
  let dropdown_click_handle = useCallback(()=>{}, []);
  let tcode_modal_close_handle = useCallback(()=>{
    is_tcode_modal_open_set(false);
  }, []);
  return (
    <div style={{display: 'flex', flexDirection: 'column', overflow: 'hidden',
      height: '100%', gap: '8px'}}>
      <Tcode_modal is_open={is_tcode_modal_open} root_dir={root_dir}
        on_close={tcode_modal_close_handle} path={selected_objs[0]} />
      <Tbin_col_choose_modal is_open={is_col_choose_modal_open}
        options={col_opts} col_keys={col_keys}
        on_close={col_choose_modal_close_handle}
        on_submit={col_choose_modal_submit_handle} />
      <div style={{display: 'flex', justifyContent: 'flex-end', gap: '8px'}}>
        <Select options={col_preset_options} style={{width: '200px'}}
          value={select_value} onChange={select_change_handle} />
        <Button title={t('Display bin In Text View mode')} size="middle"
          onClick={text_view_mode_click_handle}>
          <Text_view_mode_icon />
        </Button>
        <Button title={t('Display bin In Frame View mode')} size="middle"
          onClick={frame_view_mode_click_handle}>
          <Frame_view_mode_icon />
        </Button>
        <Input size="small" allowClear value={query}
          styles={{input: {borderRadius: 0}}}
          onChange={query_change_handle} prefix={<SearchOutlined />} />
        <Dropdown menu={{items: dropdown_items, onClick: dropdown_click_handle}}
          trigger={['click']}>
          <Button title={t('Display bin In Frame View mode')} size="middle">
            <MoreOutlined />
          </Button>
        </Dropdown>
      </div>
      {view_mode == 'text' && <div style={{width: '100%', overflowX: 'auto',
        height: '100%', position: 'relative'}}>
        <Dropdown menu={{items: row_dropdown_items,
          onClick: row_dropdown_click_handle}} trigger={['contextMenu']}
        open={is_row_ctx_open} onOpenChange={row_open_change_handle}>
          <Dropdown menu={{items: header_dropdown_items,
            onClick: header_dropdown_click_handle}} trigger={['contextMenu']}
          open={is_header_ctx_open} onOpenChange={header_open_change_handle}>
            <div style={{height: '100%', width: '100%', position: 'absolute'}}>
              <DndContext sensors={sensors} onDragEnd={drag_end_handle}
                modifiers={[restrict_to_horizontal_axis]}
                onDragOver={drag_over_handle}
                collisionDetection={closest_center}>
                <SortableContext items={cols_in_use.map(col=>col.key)}
                  strategy={horizontal_list_sorting_strategy}>
                  <Drag_idx_ctx.Provider value={drag_idx}>
                    <Table columns={cols_in_use} dataSource={data_src}
                      size="small" pagination={false} rowKey="file_orig"
                      rowClassName={row_class_name_handle} onRow={row_handle}
                      style={{width: '100%'}} scroll={{x: 'max-content'}}
                      components={{header: {cell: Tbin_table_header_cell},
                        body: {cell: Tbin_table_body_cell}}} />
                  </Drag_idx_ctx.Provider>
                </SortableContext>
                <DragOverlay>
                  <th style={{backgroundColor: '#5987B6', padding: '8px',
                    marginBottom: '1px', fontWeight: 600, fontSize: '16px',
                    lineHeight: 1.5}}>
                    {cols_in_use[cols_in_use.findIndex(i=>{
                      return i.key == drag_idx.active;
                    })]?.title}
                  </th>
                </DragOverlay>
              </DndContext>
            </div>
          </Dropdown>
        </Dropdown>
      </div>}
      {view_mode == 'frame' && <div style={{display: 'flex', flexWrap: 'wrap',
        gap: '4px', padding: '4px', overflowY: 'auto'}}>
        {Object.values(probes).map(streams=>{
          let stream = streams[0];
          return (
            <div key={stream.file_orig} style={{width: '160px', height: '100px',
              display: 'flex', flexDirection: 'column', overflow: 'hidden',
              background: selected_objs.includes(stream.file_orig)
                ? '#5987B6' : '#3D3D3D', cursor: 'pointer',
              borderRadius: borderRadiusLG}}
              onClick={card_click_handler_get(stream.file_orig)}>
              <div style={{height: '80px', background: '#222'}} />
              <div style={{overflow: 'hidden', padding: '2px',
                whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                {stream.file_transcode}
              </div>
            </div>
          );
        })}
      </div>}
    </div>
  );
});
let E = ()=>{
  let {t} = useTranslation();
  let {token: {colorBgContainer}} = theme.useToken();
  let [message_api, message_ctx_holder] = message.useMessage();
  let [probes, probes_set] = useState({});
  let [tree_data, tree_data_set] = useState([]);
  let [selected_paths, selected_paths_set] = useState([]);
  let [output_dir, output_dir_set] = useState(null);
  let [root_dir, root_dir_set] = useState(null);
  useEffect(()=>{
    if (!root_dir)
      return;
    let basename = root_dir.split('/').at(-1);
    tree_data_set([{title: basename, key: root_dir}]);
  }, [root_dir]);
  use_effect_eserf(()=>eserf(function* use_effect_dirs_get(){
    let output_dir_res = yield ereq.get(
      `${config_ext.back.app.url}/pub/fs/output_dir_get.json`,
      {qs: {ver: config_ext.ver}});
    if (output_dir_res.err)
      return;
    output_dir_set(output_dir_res.data.output_dir);
    let root_dir_res = yield ereq.get(
      `${config_ext.back.app.url}/pub/fs/root_dir_get.json`,
      {qs: {ver: config_ext.ver}});
    if (root_dir_res.err)
      return;
    root_dir_set(root_dir_res.data.root_dir);
  }), []);
  use_effect_eserf(()=>eserf(function* use_effect_probe(){
    let _probes = {};
    for (let path of selected_paths)
    {
      let node = tree_data_get(tree_data, path);
      if (!node.isLeaf)
        continue;
      if (probes[path])
      {
        _probes[path] = probes[path];
        continue;
      }
      let out_path = output_dir + '/' + 'probe.json'
      let cmd_res = yield ereq.get(
        `${config_ext.back.app.url}/pub/tcoder/cmd.json`,
        {qs: {ver: config_ext.ver, cmd: 'probe', in: path, out: out_path}});
      if (cmd_res.data.err)
      {
        message_api.error(t('Something went wrong'));
        return metric.error('pub_tcoder_cmd', cmd_res.err);
      }
      let download_res = yield ereq.get(
        `${config_ext.back.app.url}/probe.json`);
      if (download_res.data.err)
      {
        message_api.error(t('Something went wrong'));
        return metric.error('probe_download', download_res.err);
      }
      _probes[path] = download_res.data;
    }
    if (_.isEqual(Object.keys(probes), Object.keys(_probes)))
      return;
    probes_set(_probes);
  }), [probes, selected_paths]);
  return (
    <Layout style={{minHeight: '90vh'}}>
      {message_ctx_holder}
      <Header />
      <Layout>
        <Layout.Sider width="25%" style={{background: colorBgContainer,
          padding: '0 24px 24px'}}>
          <File_tree selected_paths={selected_paths} tree_data={tree_data}
            on_selected_paths_change={selected_paths_set}
            on_tree_data_change={tree_data_set} />
        </Layout.Sider>
        <Layout.Content style={{padding: '24px'}}>
          <Bin_panel probes={probes} root_dir={root_dir} />
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

export default E;
