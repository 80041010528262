// LICENSE_CODE MIT
import assert from 'assert';
import csvtojson from 'csvtojson';
import eserf from './eserf.js';
import xurl from './xurl.js';
import str from './str.js';

let E = {};
export default E;

E.c2j = file=>{
  return eserf.to_nfn(csvtojson().fromFile(file), (err_null, err, arr)=>{
    if (err)
      return {err};
    for (let i=0; i<arr.length; i++)
    {
      let json = str.j2s(arr[i]);
      arr[i] = xurl.json_parse(json);
    }
    return arr;
  });
};

E.row = (...cols)=>{
  let ret = '';
  for (let col of cols)
  {
    if (col && !col.replaceAll)
      assert(0, col+' is not string');
    let _col = (col||'').replaceAll('"', '""');
    ret += `"${_col}",`;
  }
  return ret.slice(0, -1)+'\n';
};
